const homeUrl= 'https://marketplace-v2.buupass.com/marketplace/';
// const flightsUrl='https://itinerary.buupass.com/';
const flightsUrl='https://dev.itinerary.buupass.com/';

//const flightsUrl='https://flights-api.buupass.com/';
const v2P='https://pay-v2.buupass.com/payments/';
const usersApi='https://v2-user-management.buupass.com/user-management/users/';
const usersApiLogin='https://v2-user-management.buupass.com/user-management/users/login';
export const environment = {
  production: false,
  api_key:'66Shf2aP.owT6xw6QKSWNO9EeQIIlz20JM3nNHul3',
  // search
  searchFlights: flightsUrl + 'api/v1/search/flights',
  checkFlights: flightsUrl + 'api/v1/check/flights',
  checkFlightsDev: flightsUrl + 'api/v1/check/flights',
  getAirlines: flightsUrl + 'api/v1/kiwi/carriers',
  saveBooking:flightsUrl + 'api/v1/booking/save',
  // home page
  getCredentials: flightsUrl + 'api/v1/flights/credentials',
  getToken: flightsUrl + 'api/v1/oauth/token',
  getKiwiCities: flightsUrl + 'api/v1/kiwi/locations?search=',
  confirmPayments: flightsUrl + 'api/v1/payment/check/status?booking_id=',
  confirmBooking: flightsUrl + 'api/v1/booking/confirm_payment',
  paymentQuery: 'https://pay.buupass.com/query_status',
  v2confirm: v2P+'c2b/transaction/status/',
  retrieveEmail: usersApi+'retrieve/',
  resetPassword: usersApi+'password-reset',
  signUp: usersApi+'registration',
  loginUser:usersApi+'login',
  getProfile: usersApi+'profile',
  usersApiLogin:usersApiLogin,
  getTrips: homeUrl+'booking/search?search=',

  // Travel Duqa
  apiUrl: flightsUrl,
  getTokenCred: flightsUrl + 'token/',
  getCities: flightsUrl + 'destinations/',
  // Prod Keys
  // itinerary_client_id: 'Qsu_dgNu7oar7VPvRuxLbg',
  // itinerary_client_secret_key: 'VYdRuDHUvureG9eTt3N6cZ-3p_WUniAbLs7NTNDevts',
  // Dev Keys
  itinerary_client_id:'Gl0p8B13herE9DWn3ag4Gw',
  itinerary_client_secret_key:'D9NHbmpVkos-nIdl6pLaIkwGSvGyv5R4vxz5QWcqYT0'

};
